@import '~www/themes/doctors/common/variables';

body {
    font-family: $font-family-regular;
    font-size: 14px;
}

@media only screen and (min-device-width : 500px){
    body{
        font-size: 16px;
    }
}

.google_ad {
    width: 320px;
    height: 100px;
}

@media (max-width: 300px) {
    .google_ad {
        width: 268px;
    }
}

@media (min-width: 400px) {
    .google_ad {
        width: 368px;
    }
}

@media (min-width: 500px) {
    .google_ad {
        width: 468px;
    }
}

@media (min-width: 800px) {
    .google_ad {
        width: 728px;
    }
}

.no_padding {
    padding: 0 !important;
}

/* 0071FF */
.ui-content, .ui-page-theme-a .ui-btn {
    background-color: #f6f6f6;
}

.ui-page-theme-a a, html .ui-bar-a a, html .ui-body-a a, html body .ui-group-theme-a a {
    font-weight: normal;
}

.nowrap {
    white-space: nowrap;
}

#schedule .ui-content {
    padding-left: 0;
    padding-right: 0;
}

.ui-panel-wrapper {
    padding-bottom: 35px;
    box-sizing: border-box;
}

li > a:not(:hover):not(.ui-btn-active) {
    background-color: white !important;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: 500 !important;
}

.clear {
    clear: both;
}

.center {
    text-align: center !important;
}

#map_page, #map_page > .ui-panel-wrapper {
    height: 100%;
    position: relative;
}

#contacts.map_page, #contacts.map_page > .ui-panel-wrapper {
    height: 100%;
}

#panel,
.ui-panel.panel {
    background-color: #000;
}

#panel li a,
.ui-panel.panel .ui-panel-inner li a.ui-btn {
    background-color: #000 !important;
    color: #fff !important;
    text-shadow: none;
}

#panel li a.current,
.ui-panel.panel .ui-panel-inner li a.current {
    font-weight: 500;
    color: #ffff00 !important;
}

#page {
    padding-bottom: 0 !important;
}

h2, h3, .ui-listview>li p, .ui-header .ui-title, .ui-footer .ui-title, .ui-btn, .ui-li-static  {
    white-space: normal !important;
}

/*.ui-title .ui-btn {*/
    /*margin: 0 !important;*/
/*}*/

a h2, a h3 {
    max-width: 95%;
}

.ui-listview h2, .ui-listview h3, .ui-listview h4, .ui-listview p {
    margin: 0 !important;
}

.ui-listview .ui-btn h2, .ui-listview .ui-btn h3, .ui-listview .ui-btn h4 {
    margin-top: 5px !important;
}

#main_header .ui-title, #search_header .ui-title, #schedule_header .ui-title, #filters_header .ui-title {
    display: table-row;
    height: 40px;
    margin: 0;
    padding: 0;
    text-align: left;
}

#main_header .ui-btn:not(.ui-input-clear), #search_header .ui-btn, #schedule_header .ui-btn, #header_find, #filters_header .ui-btn {
    background-color: #cce9ff;
    border-color: #fff;
    text-shadow: none;
}

#header_find {
    display: table-cell;
    width: 100%;
    border-width: 0;
    font-weight: normal;
    padding: 1px;
}

#header_find .ui-input-search {
    margin: .15em 0 0 0;
    padding-right: 0;
}

#header_find .twitter-typeahead {
    width: 100%;
}

#main_header .ui-btn:hover, #search_header .ui-btn:hover, #schedule_header .ui-btn:hover, #filters_header .ui-btn:hover {
    text-shadow: none;
    box-shadow: none;
}

#main_header, #search_header, #schedule_header, #filters_header {
    height: 35px;
    border-bottom: none;
    z-index: 100;
    width: 100%;
}

#footer div {
    font-weight: normal;
    margin: 5px;
    font-size: 1.1em;
    line-height: 1.5em;
}

#footer a {
    margin: 5px;
}

#age_rate {
    width: 15%;
    float: left;
    font-size: 1.6em !important;
    text-align: center;
}

#login, #register {
    color: #0091ff;
}

a.doctor_auth, a.lpu_auth {
    line-height: 25px;
}


.ui-li-has-count a {
    padding-right: 4.5em !important;
}

/* FIX collapsible */
.ui-li-static.ui-collapsible > .ui-collapsible-heading {
    margin: 0;
}
.ui-li-static.ui-collapsible {
    padding: 0;
}
.ui-li-static.ui-collapsible > .ui-collapsible-heading > .ui-btn {
    border-top-width: 0;
}
.ui-li-static.ui-collapsible > .ui-collapsible-heading.ui-collapsible-heading-collapsed > .ui-btn,
.ui-li-static.ui-collapsible > .ui-collapsible-content {
    border-bottom-width: 0;
}
/*************************/

.ui-listview .ui-li-has-thumb>img:first-child {
    left: 5px;
}

.ui-listview>.ui-li-static.ui-li-has-count {
    padding-right: 0px;
}

.ui-listview>li p {
    font-size: 1em;
}

h1, .pseudo_h1 {
    font-size: 16px;
    white-space: normal !important;
    margin-top: 0 !important;
    font-weight: 500;
}

h1 div, .pseudo_h1 div {
    font-size: 12px;
    font-weight: normal;
}

h1.drug_h1 {
    font-size: 18px !important;
}

h1.druglist_h1 {
    margin-bottom: 20px;
}

h2 {
    font-size: 16px;
}

h3 {
    font-size: 14px;
}

.ui-btn-icon-notext.ui-btn-left.ui-icon-bars, .ui-btn-icon-notext.ui-btn-right.ui-icon-search {
    top: 0.5em;
}

.error_text {
    background-color: red;
    border: 1px solid #da0000;
    /*display: inline-block;*/
    color: #ffffff;
    display: none;
    font-weight: 500;
    /*width: 280px;*/
    padding: 4px;
}

.hidden {
    display: none;
}

/*rates*/
div.p, div.pp, div.m, div.mm, div.z {
    border: #ffffff 2px solid;
    border-radius: 4px 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px 4px;
    -webkit-border-radius: 4px 4px 4px 4px;
    /*display: inline;*/
    display: inline-block;
    /*border-style: solid solid solid solid;*/
    font-weight: 500;
    height: 30px;
    line-height: 30px;
    text-align: center;
    text-shadow: none;
    width: 30px;
    zoom: 1;
    -khtml-border-radius: 4px 4px 4px 4px;
}

div.p:hover, div.pp:hover, div.m:hover, div.mm:hover, div.z:hover {
    cursor: pointer
}

div.txt {
    display: inline-block;
    display: -moz-inline-stack;
    height: 20px;
    /*display: inline;*/
    margin-left: 6px;
    /*width: 60px;*/
    padding-top: 8px;
    vertical-align: top;
    zoom: 1;
    _height: 30px;
}


.error_mark, .gender_error, .age_error, .pay_error, .diagnos_error {
    background-color: red;
    border: 1px solid #da0000;
    color: #ffffff;
    display: none;
    font-size: 0.8em;
    /*width: 120px;*/
    font-weight: 500;
    padding: 2px;
    text-align: center;
}

.error_mark, .error_text, .gender_error, .age_error, .pay_error, .diagnos_error {
    text-shadow: none;
}

.draft_status br {
    display: none;
}

.avatar {
    margin-right: 10px;
    width: 50px;
}

.pharm_doctor_rate .avatar {
    float: left;
}

.doctor_info {
    overflow: hidden;
}

.comment, .comment_plus, .comment_minus {
    min-height: 30px;
    /*padding-left: 25px;*/
    text-indent: 20px;
}

.comment_plus {
    background: url("/static/_v1/pd/icons/review/comment-plus.png") no-repeat scroll left top transparent;
}

.comment_minus {
    background: url("/static/_v1/pd/icons/review/comment-minus.png") no-repeat scroll left top transparent;
}

.comment {
    background: url("/static/_v1/pd/icons/review/comment.png") no-repeat scroll left top transparent;
}


.upload_container {
    display: none;
}

.diagnos_container .ui-btn-inline {
    max-width: 65%;
}

#delete_draft_popup .ui-header .ui-title {
    margin: 0 20%;
}

.advantages {
    left: 100px;
    position: relative;
}

.rate_status_moderation {
    color: blue;
}

.rate_status_rejected {
    color: red;
}

.grn, .green {
    color: green;
}

.red {
    color: red;
}


/* consult */
.doctor_avatar, .patient_avatar {
    float: left;
    margin-right: 10px;
    max-width: 50px;
    object-fit: cover;
    height: 50px;
    width: 50px;
}

.mobile_operator_img {
    margin-right: 10px;
    max-width: 50px;
    vertical-align: top;
}

.question_datetime, .rate_datetime {
    float: right;
    font-size: 0.8em;
    text-align: right;
}

.rate_datetime {
    position: relative;
    padding-left: 25px;
}

.errors {
    color: red;
}

.howto_collapse {
    float: right;
}

.error_field {
    box-shadow: 0 0 12px #f10000;
}

.scroll_next {
    display: none;
}

.question_header {
    padding-left: 18px;
}

.question_closed {
    background: url('/static/_v1/mo/icons/closed.png') no-repeat scroll left top transparent;
}

.question_opened {
    background: url('/static/_v1/mo/icons/open.png') no-repeat scroll left top transparent;
}

.question_card_datetime {
    font-size: 0.7em;
    font-weight: normal;
    position: absolute;
    right: 10px;
    text-align: right;
    top: 5px;
}

.question_answers {
    bottom: 2px;
    color: #0091ff;
    font-size: 0.8em;
    position: absolute;
    right: 10px;
}

div.img {
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
}

#id_age {
    /*width: 75px;*/
}

.agefield {
    display: inline-block;
    width: 75px;
}

.agefield > div {
    margin: 0 !important;
}

.q_image {
    border-bottom: none;
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
}

.q_image img {
    max-height: 150px;
    max-width: 50px;
}

.img_number {
    background-color: #ffffff;
    border: 2px solid grey;
    border-radius: 15px;
    bottom: 10px;
    height: 8px;
    padding: 2px 6px;
    position: relative;
    right: 10px;
    width: 8px;
}

.awards {
    bottom: 2px;
    position: absolute;
    right: 5px;
}

img.transparent {
    filter: alpha(opacity=10);
    opacity: 0.1;
}


/* info */

.o_proekte li {
    font-size: 1.3em;
    font-weight: 500;
    margin-top: 10px;
}

.auth_table td, .auth_table th {
    border-bottom: 1px solid #ccc;
}

.status1, .status2 {
    font-weight: 500;
}

.status1 {
    color: green;
}

.status2 {
    color: red;
}

.status0 {
    color: blue;
}

.blue-button {
    border: 1px solid #3466F4 !important;
    color: #3466F4 !important;
}

.blue {
    color: #0054b9 !important;
}

.list_blue a {
    color: #0054b9 !important;
}

a.black_link {
    color: black !important;
}

.block .num {
    border: 1px solid #0091ff;
    border-radius: 1em;
    color: #0091ff;
    display: inline-block;
    font-size: 1.2em;
    font-weight: 500;
    height: 1.2em;
    margin:5px;
    text-align: center;
    width: 1.2em;
}

.illustr {
    max-width: 100%;
}

.auth_table td,
.auth_table th,
.auth_table tbody th,
.auth_table tbody td,
.auth_table thead td,
.auth_table thead th {
    display: table-cell;
    margin: 0;
}

.auth_table td .ui-table-cell-label,
.auth_table th .ui-table-cell-label {
    display: none;
}

.ui-table-reflow tbody th {
    margin-top: 1.4em;
}

.ui-table-reflow td .ui-table-cell-label {
    font-weight: normal;
    min-width: 50%;
}


@media only screen and (max-width : 230px) {
    .g-recaptcha {
        transform: scale(0.60);
        transform-origin: 0 0;
    }
}


@media only screen and (max-width : 280px) {
    .g-recaptcha {
        transform: scale(0.77);
        transform-origin: 0 0;
    }
}

@media only screen and (max-width : 320px) {
    .g-recaptcha {
        transform: scale(0.90);
        transform-origin: 0 0;
    }
}

/*Lpulist*/
.lpu_logo, .doctorlist_avatar, .manuf_img {
    bottom: 0;
    left: 10px !important;
    top: 10px !important;
    /*margin: auto;*/
}

.ui-listview>.ui-li-has-thumb>.ui-btn, .ui-listview>.ui-li-static.ui-li-has-thumb {
    padding-left: 90px;
}

.shw span {
    border-bottom: 1px dotted blue;
    color: #0091ff;
    font-weight: 500;
    line-height: 1.5em;
}

.ui-btn-active .shw span {
    color: white;
}

#pay {
    background: url("/static/_v1/pd/icons/pay.png") no-repeat scroll left center transparent;
    line-height: 25px;
    padding-left: 20px;
}

.checked {
    background: url("/static/_v1/pd/icons/tick/tick-in-circle.png") no-repeat scroll left center transparent;
    display: block;
    padding: 2px 20px;
}

#site button {
    background: none;
    padding: 0;
    border: 0;
    color: #38c;
    text-decoration: underline;
    margin: 3px 0 5px;
}

.lpuimg, .doctorimg {
    color: #000000 !important;
    text-decoration: none;
    margin-right: 0 !important;
}

.lpuimg p, .doctorimg p {
    text-align: center;
}

.lpuimg img, .doctorimg img {
    border: 2px solid #cccccc !important;
    margin: 0 auto;
    max-height: 150px;
    max-width: 200px;
}

.lpuimg div, .doctorimg div {
    margin-right: 10px;
}

.mesto {
    color: #999;
    display: inline-block;
    font-size: 0.9em;
    margin-bottom: 10px;
    /*margin-left: 10px;*/
    margin-top: -3px;
}


.lpuunion_rating {
    float: right;
    /*line-height: 1.8em;*/
}

.lpuunion_name {
    display: inline-block;
    /*max-width: 70%;*/
}

.rates_count {
    margin-left: -10px;
    line-height: 16px;
    font-weight: normal;
    color: #858585;
    font-size: 14px;
}

.rates, .doctor_consult {
    background-color: #f6f6f6 !important;
}

.rates.hidden_rates,
.rates.hidden_comments {
    border-top: 0;
    padding-top: 0;
}

.rates.hidden_rates > ul:first-child,
.rates.hidden_comments > ul:first-child {
    margin-top: 0;
}

.rates:not(.hidden_rates):not(.hidden_comments) {
    padding-bottom: 0;
}

.left_float {
    float: left;
}

.lpu_rating, .doctor_rating {
    font-size: 1.5em;
    left: 10px;
    top: 70px;
    position: absolute;
    /*top: 10px;*/
    /*right: 30px;*/
}

.lpu_rating_lst {
    position: absolute;
    top: 7px;
    right: 10px;
}

.doctor_rating {
    position: static;
    font-size: 1em;
    display: block;
    margin-top: 10px;
}

.doctor_rating_lst {
    position: absolute;
    top: 7px;
    right: 10px;
}

.lpu_rating span, .doctor_rating span {
    font-weight: 500;
}

.price_off_big {
    font-size: 1.2em;
}

.next_btn, .green_button {
    border: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    /*border-radius: 10px;*/
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: inline-block;
    font-weight: 500;
    margin-top: 1px !important;
    text-decoration: none;
    text-shadow: none !important;
    width: 132px;
    background-color: #00C000 !important;
    color: white !important;
}

.lpuaddr {
    color: gray;
    font-weight: normal;
}

.ui-btn-active .lpuaddr {
    color: white;
}

.rate {
    margin: 20px 0px 0px 0px !important;
}
#rates > div{
    padding: 5px !important;
}

.moder {
    margin-left: 10% !important;
    margin-top: 5px !important;
    width: 90%;
    font-size: 0.9em;
}

.moder div:first-child {
    color: #0091ff;
    font-family: $font-family-regular;
    margin-bottom: 3px;
}

.rate_pay {
    bottom: 5px;
    position: absolute;
    right: 5px;
}

.avg_rate {
    background-color: #0091ff;
    border-radius: 5px;
    color: white;
    display: inline-block;
    font-size: 1.2em;
    font-weight: 500;
    height: 25px;
    line-height: 25px;
    margin-bottom: 5px;
    text-align: center;
    width: 40px;
}

.avg_text {
    color: #0091ff;
    font-size: 1.2em;
    font-weight: 500;
}

.super {
    border-spacing: 0;
}

.super td, .super2 td {
    padding: 5px;
}

.super tr:not(:last-child) td, .super2 td {
    border-bottom: 1px solid #cdcdcd;
    /*line-height: 24px;*/
}

.ser, .ser a, a.ser {
    color: #999999;
}


/* doctor */
.doctorlist_card {
    min-height: 100px !important;
    padding-top: 0px;
}

.samopiar {
    position: absolute;
    top: 4px;
    width: 40px;
    left: 0;
}

.rnd-list div {
    float: left;
    margin: 5px 1px 0;
    height: 10px;
}

.rnd-full {
    background: #0B0;
    width: 10px;
    height: 10px;
    border-radius: 10px;
}


.rnd-empty {
    background: #EEE;
    width: 10px;
    height: 10px;
    border-radius: 10px;
}

.rnd-list div.label {
  margin: 1px;
}

.rnd-list {
    display: block;
    overflow: hidden;
    padding-bottom: 3px;
}

.dconsult_avatar_patient {
    float: right;
    max-width: 50px;
    margin-left: 10px;
}

.drug_rate70 {
    margin: 5px 0;
    display: inline-block;
}

.phone {
    font-size: 1.3em;
    line-height: 2em;
}

.phone_link {
    line-height: 2em;
}

.rate_btn {
    font-size: 0.9em;
    padding: 5px;
    margin: 5px;
}

.smsprices {
    width: 100%;
    border-spacing: 0;
}

.smsprices td {
    border-bottom: 1px solid #cdcdcd;
}

.smsprices th {
    text-align: left;
}

.primech {
    padding-left: 20px;
}

.txttable {
    width: 100%;
}

.txttable th {
    text-align: left;
}

.rate_vote {
    /*display: inline-block;*/
    padding: 5px 10px 5px 30px;
    height: 40px;
    display: table-cell;
    vertical-align: middle;
    min-width: 100px;
}

.rate_vote img {
    position: absolute;
    left: 3px;
    top: 14px;
}

.doc_spec_mesto {
      color: #999;
      margin-left: 10px;
}

.doctor_speclist a {
    text-decoration: none;
}

.workplaces {
    margin-left: -70px;
}

#map {
    width: 100%;
    /*height: 100% !important;*/
}

.spec_prices {
    /*display: inline-block;*/
    /*margin-left: 30px;*/
    margin: 5px 0px 5px 30px;
}

.lpu_address .address_text {
    display: inline-block;
    font-size: 1.1em;
    font-weight: 500;
}

.doctor_wp {
    background-color: white !important;
    padding: 5px 30px 5px 10px;
    border-top: 1px solid #ddd;
    margin-top: 10px;
}

.doctor_wp:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

.doctor_addresses_phone {
    background-color: white !important;
    border-bottom: 1px solid #ddd;
}

.doctor_addresses_phone a {
    text-decoration: none;
    padding: 10px 0;
}

/* appointment */
.order_data .phone_number {
    padding-left: 24px;
    padding-top: 6px;
    vertical-align: middle;
}

.ui-radio p {
    margin: 0 !important;
}

/* find */

.find_spec {
    font-weight: 500;
    color: gray;
}



/* towns */

.h1_change {
    float: left;
    max-width: 70%;
}

.change_town, .change_lputype, .change_spec {
    float: left;
    padding-left: 10px;
    padding-top: 3px;
}

.change_town a, .change_lputype a, .change_spec a {
    color: gray !important;
}

/* profile */

a.moredetail {
    color: #e00 !important;
    font-size: 1.2em !important;
    font-weight: 500 !important;
}

.towns_on_main{
    margin-top: 10px;
}

.appointment_sticker {
    background-color: #00C000 !important;
    color: white !important;
    text-shadow: none !important;
}


.app_phone{
    margin: 15px 0 0px;
    font-size: 1.2em;
    font-weight: 500;
}
.app_phone a{
    color: #00AA00 !important;

}
.list_fio{
    margin-bottom: 0;
    display: inline-block;
    font-size: 1.2em;
    line-height: 20px;
    font-weight: 500;
}

.list_fio .io {
    font-size: 0.9em;
}

.rate_form {
    width: 100%;
}

.list_title .prg-url__form {
    float: right;
}

.rate>li.ui-first-child{
    min-height: 40px;
    /*background: #eee;*/
}

.rate > li.ui-last-child {
    border-top: none;
}

.ui-footer-fixed {
    text-align: center;
}
.ui-footer-fixed .ui-btn{
    padding: .7em .6em !important;

}

.ui-footer-fixed .ui-btn-active{
    background: #eee !important;
    color: black !important;
    border-color: gray !important;

    text-shadow: 0 1px 0 #eee !important;
}

.ui-doctor {
	background: url("/static/img/mobile/sprite.png") 50% 50% no-repeat;
    background-size:24px 24px;
    background-color: transparent;
    box-shadow: none;
    padding-left: 30px;
    margin-left:5px !important;
}

.no_appointment {
    font-size: 0.8em;
}

.appointment_address {
    font-weight: 500;
    /*padding-top: 10px;*/
    font-size: 1.2em;
}

.appointment_lpu {
    /*padding-left: 25px;*/
    white-space: nowrap;
}

.appointment_workplace:hover {
    cursor: pointer;
}

.appointment_spec_price {
    top: 5px;
    left: 225px;
    color: #0091FF;
    font-weight: 500;
    font-size: 1em;
}

.schedule_body {
    padding-left: 20px;
}

.schedule_day {
    display: inline-block;
    width: 70px;
    vertical-align: top;
}

.schedule_day:not(:first-child) {
    border-left: 1px solid gray;
    padding-left: 10px;
}

.error_input {
    border: 1px solid red !important;
}

.diaglist > li > a {
    min-height: 100px !important;
}

.diaglist > li {
    padding-left: 95px !important;
    padding-bottom: 5px !important;
}

.appointment_form_submit {
    margin: 0.5em 0 10px 0 !important;
    width: 100% !important;
}

.dclass_rating {
    font-size: 1.1em;
    font-weight: 500;
}

.price_from {
    font-weight: normal !important;
    color: gray !important;
}

.num_projections {
    color: green;
    font-weight: 500;
}

.lpu_diag_phone {
    white-space: nowrap;
}

.lpu_diag_phone a {
    font-weight: 500;
    margin: 10px 5px;
    display: inline-block
}

li.diag {
    border-width: 1px !important;
}

span.stars {
    margin-right: 20px;
}

span.stars, span.stars span {
    display: block;
    width: 80px;
    height: 16px;
    background: transparent url("/static/_v1/pd/icons/old-stars/star-16-off.png") repeat-x scroll 0px 0px;
}

span.stars span {
    background: transparent url("/static/_v1/pd/icons/old-stars/star-16-on.png") repeat-x scroll 0px 0px;
}

span.balloon_stars, span.balloon_stars span {
    height: 12px;
    width: 60px;
    background-size: 12px;
}

span.balloon_stars {
    margin-right: 15px !important;
    float: left;
}

.doctorlist_lpu_name, .doctorlist_phone {
    padding-left: 10px;
}

.register-btn {
    position: absolute;
    left: 20px;
    top: 150px;
}

.register-btn__link {
    color: #9c9c9c;
    margin: 0;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
    background: none;
    border: none;
}

.doctor_stars, .drug_stars {
    position: relative;
    bottom: 4px;
}

.pagination a {
    margin-left: 10px;
    background-color: white !important;
    color: #0054b9 !important;
}

.manuflist_pagination {
    margin-top: 10px;
}

.more_doctors {
    float: right;
}

.block10 {
    width: 10% !important;
}

.block20 {
    width: 20% !important;
}

.block30 {
    width: 30% !important;
}

.block40 {
    width: 40% !important;
}

.block60 {
    width: 60% !important;
}

.block70 {
    width: 70% !important;
}

.block80 {
    width: 80% !important;
}

.block90 {
    width: 90% !important;
}

.block_town_left {
    width: 100% !important;
}

.block_town_right {
    width: 40px !important;
}

.list_filter > div:first-child {
    height: 45px;
    line-height: 45px;
    padding-right: 10px;
    text-align: right;
}

a.doctor_phone_app {
    display: block;
    color: black !important;
    margin-left: 30px;
    font-size: 1.1em;
}

a.doctor_phone_app {
    font-size: 1.5em;
}

.lpu_phone_container {
    padding: 10px 20px !important;
    border-top: 1px solid #ddd !important;
    border-bottom: 0 !important;
}

.lpu_phone_container a {
    text-decoration: none;
}

.gray_background {
    background-color: #f6f5f3;
}

.no_border {
    border: none !important;
}

.collegi_all {
    text-align: center !important;
}

.list_title {
    /*border-bottom: 1px solid #ddd !important;*/
    padding: 20px 15px 5px 15px !important;
    font-size: 1.2em !important;
    background-color: #f6f6f6 !important;
}

.doctor_ul_padding {
    padding: 0 14px;
}

.town_btn, .menu_btn {
    box-sizing: border-box;
    display: table-cell !important;
}

.menu_btn {
    border: 0;
}

.town_btn {
    width: 100%;
}

.menu_btn {
    min-width: 40px;
}

.map_container {
    padding: 0;
    width: 100%;
    /*height: 90%;*/
    position: absolute;
}

.menu_icon {
    background-size: 16px;
}

.header_town {
    /*font-size: 1.1em !important;*/
    padding-top: 7px;
    font-weight: 500 !important;
    color: #0071ff !important;
}

.video_container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}

.video_container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.full_schedule_day {
    display: inline-block;
    width: 30px;
    color: #999;
}

.schedule_title {
    text-align: center;
    line-height: 22px;
}

.h1_page_num {
    color: gray;
    font-size: 0.7em;
    font-weight: normal;
    padding-left: 10px;
    white-space: nowrap;
}

.left0 {
    margin-left: 0;
}

.see_also_lpulist {
    margin-top: 20px;
    line-height: 20px;
}

ul.search_results {
    margin-top: -5px !important;
    margin-bottom: 10px !important;
}


.tt-suggestion {
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.tt-cursor {
    background-color: #EEE !important;
}

.tt-dataset:not(:first-child) {
    border-top: 1px solid red;
}

.tt-suggestion img, #tab-best > div img {
    max-height: 30px;
    max-width: 20px;
    vertical-align: middle;
    padding-right: 5px;
    width: 20px;
    display: inline-block;
}

.tt-suggestion img[src=""] {
    visibility: hidden;
}

.tt-suggestion img ~ span, #tab-best > div img ~ span {
    vertical-align: middle;
}

.find_all {
    color: #0071FF;
    font-weight: 500;
    text-align: center;
    background-color: whitesmoke;
}

.tt-menu {
    background: white none repeat scroll 0% 0%;
    width: 100%;
    max-height: 510px;
    overflow-y: auto;
    border: 1px solid #aaa;
    margin-top: 3px;
    border-radius: 5px;
}


.tt-suggestion {
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tt-cursor {
    background-color: #EEE !important;
}

.tt-dataset:not(:first-child) {
    border-top: 1px solid red;
}


.tt-suggestion img, #tab-best > div img {
    max-height: 30px;
    max-width: 20px;
    vertical-align: middle;
    padding-right: 5px;
    width: 20px;
    display: inline-block;
}

.tt-suggestion img[src=""] {
    visibility: hidden;
}

.tt-suggestion img ~ span, #tab-best > div img ~ span {
    vertical-align: middle;
}

#search .twitter-typeahead {
    position: static !important;
    display: block !important;
}

#search .ui-input-search {
    padding-right: 0;
}

.find_speciality:nth-child(10) ~ .find_speciality {
    display: none;
}

.find_speciality, .find_serviceclass, .find_servicetype, .find_lpu_all, .find_vrach_all, .find_town,
.find_manipulation, .find_drugtag, .find_all_spec, .find_all_lputypes, .find_all_towns, .find_drug,
.find_lputype {
    background-size: 20px !important;
    padding-left: 35px;
}

.find_speciality, .find_serviceclass, .find_servicetype, .find_lpu_all, .find_vrach_all,
.find_manipulation, .find_drugtag, .find_all_spec, .find_all_lputypes, .find_lputype, .find_speciality {
    background: url('/static/_v1/pd/icons/plus/add-gray.png') no-repeat 10px center;
}

.find_town, .find_all_towns {
    background: url('/static/_v1/pd/icons/pins/pin-gray.png') no-repeat 10px center;
}

.find_subways {
    background: url('/static/_v1/pd/icons/pins/pin-gray.png') no-repeat 10px center;
}

.find_drug {
    padding-left: 10px;
}

.find_drug > .rates_count {
    color: #999;
    margin-left: 1em;
}

.find_doctor_fio, .find_doctor_speclist {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.find_doctor_fio {
    width: 50%;
}

.find_doctor_speclist {
    width: 35%;
}

.manuf_name {
    margin-left: 10px;
    display: inline-block;
}

.manuf_name_margin {
    margin-left: 84px;
}

p.ask {
    font-weight: 500 !important;
    margin-top: 10px !important;
    margin-bottom: 3px !important;
}

.rate_confirmation_container .sms_code_help_text {
    display: none;
}

.rate_confirmation_container .confirmation_code {
    width: 77px !important;
}

.rate_confirmation_container .confirmation_code ~ button {
    height: 40px;
}

.rate_confirmation_container .ui-grid-b {
    width: 190px;
    display: inline-block;
}

.rate_confirmation_container .ui-block-a {
    width: 7%;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
}

.rate_confirmation_container .ui-block-b {
    width: 68%;
    padding-left: 5px;
}

.rate_confirmation_container .ui-block-b > div {
    margin: 0;
}

.confirmation_container .confirmation_code {
    height: 40px !important;
    font-size: 1.1em;
}

.rate_confirmation_container .ui-block-c {
    width: 20%;
    padding-left: 5px;
}

.rate_confirmation_container .ui-block-c button, .confirmation_container .ui-block-c input[type="submit"], .rate_confirmation_container .ui-btn:not(.send_again) {
    height: 40px;
    line-height: 40px;
    padding: 0;
    box-sizing: border-box;
    width: 40px;
    margin: 0;
}

.rate_confirmation_container form.confirm_phone ~ .errors {
    margin: 5px 0px 0px 75px;
    display: inline-block;
}

.mobile_operator_img, .patient_avatar_preview {
    vertical-align: top;
    max-width: 40px;
    max-height: 40px;
}

.patient_avatar_preview {
    border: 1px solid #ccc;
}

.plus_7 {
    height: 0;
    overflow: visible;
    position: relative;
    top: 18px;
    left: 5px;
}

.appointment_call_time_link {
    border-bottom: 1px dotted black;
    cursor: pointer;
}

.login_frame {
    display: none;
}

/* lpu pricelist */

.diagnostics_price_item td.space {
    width: 10px;
    border: none !important;
}

.diagnostics_price_item .selectable {
    border-bottom: 1px solid #cdcdcd;
}

.map_back_btn {
    position: absolute;
    bottom: 40px;
    left: 20px;
    width: 32px;
    height: 32px;
    display: block;
    box-sizing: border-box;
    background: url('/static/_v1/pd/icons/arrows/circle-mono-prev.png');
    background-size: 32px;
}

.map_back_btn {
    top: 70px;
    width: 48px;
    height: 48px;
    background-size: 48px;
}

.show_all_rates {
    background: white !important;
}

.icon_next {
    background: url('/static/_v1/pd/icons/arrows/drop-right-bold.png') no-repeat right 15px center;
    background-size: 16px;
}

.doctor_addresses {
    background-color: #f6f6f6 !important;
    border-top: none;
    padding: 0 !important;
}

.doctor_activity {
    font-size: 16px;
    border-bottom: 1px solid #ddd !important;
}

.doctor_activity a {
    text-decoration: none;
}

.show_all_science > div {
    margin-bottom: 15px;
}

li.subcategory > a {
    padding-left: 25px;
}

.category_title > a {
    font-weight: 500;
}

.tag_type_header {
    margin-top: 40px;
}

.tag_type_header:first-of-type {
    margin-top: 20px;
}

/* tabs */

.goto_town.inactive {
    opacity: 0.3;
}

.druglist_price {
    color: red;
}

.druglist > li.ui-last-child > a.ui-btn {
    border-bottom-width: 0;
}

.diagnostics_group_title {
    font-weight: 500;
    font-size: 1.2em;
}

.manipulation_tabs {
    margin-bottom: 10px;
}

.merge_accounts_dialog {
    text-align: center;
    padding: 10px;
}

.merge_accounts_dialog img {
    vertical-align: middle;
    margin: 10px 5px;
}

.merge_accounts_dialog button {
    margin: 0 5px !important;
    display: inline-block;
    width: 70px !important;
}

.rate_doc_preview {
    max-height: 50px;
}

.rate_continue {
    background-color: #00C000;
    color: #FFF;
    width: 140px;
}

.rate_continue:hover {
    cursor: pointer;
    background: #33CC33 !important;
}

.rate_continue, .rate_abort_confirm {
    margin: 30px 5px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    border: 1px solid #999;
}

.rate_abort_confirm {
    opacity: 0.5;
    width: 100px;
}

.rate_abort_confirm {
    width: 122px;
}

.rate_continue {
    background-color: #00C000;
    color: #FFF;
    width: 140px;
}

.rate_continue:hover {
    cursor: pointer;
    background: #33CC33 !important;
}

.rate_status_history {
    background: url("/static/_v1/pd/icons/search/search-eye.png") left center no-repeat;
    padding-left: 30px;
    height: 24px;
    line-height: 18px;
    width: 80px;
    float: right;
}

.rate_status_history > a {
    border-bottom: 1px dotted black;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.rate_status_history > a:hover {
    color: black;
}

.rate_documents_status_popup {
    padding: 5px;
}

a.help_tooltipster {
    display: inline-block;
    width: 24px;
    background: url("/static/_v1/mo/icons/question.png") no-repeat scroll center bottom;
    height: 17px;
    position: relative;
    top: 1px;
}

.lpuset_rate_address, .doctor_rate_address {
    margin-bottom: 5px;
}

.refutation_text {
    font-style: italic;
}

#prodoctorov_mobile_banner {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

#prodoctorov_mobile_banner::after {
    display: none;
}

.page_warning_text {
    color: red;
    margin-bottom: 20px !important;
    font-size: 1.1em;
    line-height: 1.5em;
    border-top: 5px solid red;
    border-bottom: 5px solid red;
}

.lastmod_dt_left {
    float: left;
    color: #9c9c9c;
}

.mistake_right {
    float: right;
    color: #9c9c9c !important;
    text-decoration: underline;
    font-size: 14px;
}
.mistake_prg_right {
    float: right;
}

.lastmod_block {
    margin-top: 10px;
}

.twitter-typeahead {
    height: 30px;
    display: block !important;
}

.tt-menu .tt-open {
    position: absolute;
    top: 150px;
    left: 55px;
    z-index: 1001;
    display: none;
}
