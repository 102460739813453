.display {
  width: 94%;
  padding: 10px 3% 20px;
}

.display_align_left {
  text-align: left;
}

.display_bg_white {
  background-color: white !important;
}

.display_top {
  padding-top: 0;
}

.display__analogi {
  padding-top: 0;
}

.display__instruction {
  padding-top: 0;
}

.display__back {
  color: #333333;
  min-height: 400px;
}

.display__back_bg_white {
  background-color: white !important;
}

.display__list-stat {
  padding-left: 3px;
  color: #333333;
  margin-top: 13px;
  padding-right: 10px;
}

.ql-align-justify {
  margin-top: 2px;
}

.display__click {
  background-color: #0054B9 !important;
  color: white;
}

.display__no-scroll-body {
  position: fixed;
}

.display_white {
  background-color: white;
}

.display_height_min {
  min-height: 370px; /* 400px от display__back min-height - 30px display padding-bottom */
}

