.b-the-header {
  position: relative;

  &__change-flavour {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    text-align: center;
  }

  .add-point-to-cart {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 10px;
  }

  .cart-icon {
    height: 24px;
    position: relative;
  }
}
