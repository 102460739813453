.footer {
  padding-top: 12px;
  background-color: #E9E9E9;
  border: 1px solid #DDDDDD;
  color: #333333;
  font-size: 0.9em;
}

.footer__container {
  font-weight: normal;
  margin: 5px;
  font-size: 1.1em;
  line-height: 1.5em;
  margin-bottom: 20px !important;
}

.footer__text_hidden {
  display: none;
}

.footer__age {
  width: 15%;
  float: left;
  font-size: 1.6em !important;
  text-align: center;
}

.footer__terms-of-use {
  display: inline-block;
  float: right;
  margin: 0 !important;
  margin-right: 5px !important;
}

.footer__warning {
  background-image: url('/static/_v1/pd/icons/plus-18.png');
  background-position: center;
  background-position-x: 0;
  background-size: 32px;
  background-repeat: no-repeat;
  padding-left: 40px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: 1.1em;
  line-height: 1.5em;
}

.footer__clear {
  clear: both;
}

.footer__link {
  text-decoration: none;
  font-size: 15px;
  color: #333333 !important;
  font-weight: 400 !important;
  line-height: 32px;
}

a.footer__black-link {
  color: black !important;
  text-decoration: none;
  border-bottom: 1px dotted #AAAAAA;
}

.footer__link_color_btn {
  display: inline-block;
  padding: 0;
  border: none;
  background-color: #E9E9E9;
  outline: none !important;
  line-height: normal;
  margin: 6px 0;
}

.footer__black-title {
  font-size: 15px;
  font-weight: 500;
}

.footer__prg-btn {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  display: inline;
  width: 50% !important;
  margin: 0;
  font-weight: 500;
  border-bottom: 1px dotted #AAAAAA;
  outline: none !important;
}

.footer__prg-btn:active,
.footer__prg-btn:focus {
  outline: none;
}

.footer__link-wrap {
  margin: 0 !important;
  display: inline-table;
}

.footer__link-wrap_left {
  width: 50%;
}

.black_link:visited {
  color: black;
}

.footer__smi {
  font-weight: normal;
  margin: 5px;
  font-size: 1.1em;
  line-height: 1.5em;
}

.footer__certificates-wrap {
  padding-right: 20px;
}

.footer__certificates-img-wrap {
  display: inline-block;
  height: 100px;
  width: 120px;
  background-color: white;
}

.footer__certificates-img {
  width: 100%;
  height: 100%;
  background-image: url('/static/_v1/pd/documents/organization/issn-probolezny-barcode.png');
  background-repeat: no-repeat;
  background-size: 111px;
  background-position: center;
}

.footer__img {
  width: 72px;
  height: 87px;
  background-image: url('/static/_v1/pd/logos/ru-reestr-programs.png');
  background-size: contain;
}
