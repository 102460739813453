.prg-url__form {
  display: inline-block;
}

.prg-url__btn-jqm {
  background: none !important;
  box-shadow: none;
  border: none;
  display: inline;
  padding: 0;
  margin: 6px 0;
  line-height: normal !important;
  font-size: 15px;
  font-weight: 500;
  outline: none !important;
  text-align: left;
}
