@import '~www/themes/doctors/common/variables';

h1 {
    font-size: 16px;
    white-space: normal !important;
    margin-top: 0 !important;
    font-weight: 500;
}

/* Данное правило необходимо для переопределения глобальных стилей jquery.mobile.min.css */
html body {
    font-family: $font-family-regular;
    font-size: 14px;
}

body {
    margin: 0;
    width: 100%;
    background-color: #EEEEEE;
    position: relative;
}

.login_frame {
    display: none;
}

h2 {
    font-size: 16px;
}

h3 {
    font-size: 14px;
}

.bold {
    font-weight: 500;
}

#comments {
    margin-top: 25px;
    text-align: left;
}

.comment_plus {
    background: url(/static/_v1/pd/icons/review/comment-plus.png) no-repeat scroll left top transparent;
}

.comment_minus {
    background: url(/static/_v1/pd/icons/review/comment-minus.png) no-repeat scroll left top transparent;
}

.comment {
    background: url(/static/_v1/pd/icons/review/comment.png) no-repeat scroll left top transparent;
}

.comment_minus > a {
    color: #38c !important;
}

.comment_minus > a:visited {
    color: #38c !important;
}

.comment > a {
    color: #38c !important;
}

.comment > a:visited {
    color: #38c !important;
}

#rates {
    margin-top: 25px;
}

#atx_tree {
    text-align: left;
}

.hide {
    display: none;
}

.see-also {
    color: #38c;
}

.page__count {
    color: gray;
    font-size: .7em;
    font-weight: normal;
    padding-left: 10px;
    white-space: nowrap;
}

.zindex-more {
    z-index: 100;
}

.zindex-less {
    z-index: -1;
}

.find_speciality:nth-child(10) ~ .find_speciality {
    display: none;
}

.find_speciality, .find_serviceclass, .find_servicetype, .find_lpu_all, .find_vrach_all, .find_town, .find_subways,
.find_manipulation, .find_drugtag, .find_all_spec, .find_all_lputypes, .find_all_towns, .find_drug,
.find_lputype {
    background-size: 20px !important;
    padding-left: 35px !important;
}

.find_all_towns {
    padding-left: 35px !important;
}

.find_speciality, .find_serviceclass, .find_servicetype, .find_lpu_all, .find_vrach_all,
.find_manipulation, .find_drugtag, .find_all_spec, .find_all_lputypes, .find_lputype, .find_speciality {
    padding-left: 35px !important;
    background: url('/static/_v1/pd/icons/plus/add-gray.png') no-repeat 10px center;
}

.find_town, .find_all_towns {
    background: url('/static/_v1/pd/icons/pins/pin-gray.png') no-repeat 10px center;
}

.find_subways {
    background: url('/static/_v1/pd/icons/pins/pin-gray.png') no-repeat 10px center;
}

.find_drug {
    padding-left: 10px;
}

.find_drug > .rates_count {
    color: #999;
    margin-left: 1em;
}

.find_doctor_fio, .find_doctor_speclist {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.find_doctor_fio {
    width: 50%;
}

.find_doctor_speclist {
    width: 35%;
}

.tt-suggestion {
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.tt-cursor {
    background-color: #EEE !important;
}

.tt-dataset:not(:first-child) {
    border-top: 1px solid red;
}

.tt-suggestion img, #tab-best > div img {
    max-height: 30px;
    max-width: 20px;
    vertical-align: middle;
    padding-right: 5px;
    width: 20px;
    display: inline-block;
}

.tt-suggestion img[src=""] {
    visibility: hidden;
}

.tt-suggestion img ~ span, #tab-best > div img ~ span {
    vertical-align: middle;
}

.find_all {
    color: #0071FF;
    font-weight: 500;
    text-align: center;
    background-color: whitesmoke;
}

.tt-menu {
    background: white none repeat scroll 0% 0%;
    width: 100%;
    max-height: 510px;
    overflow-y: auto;
    border: 1px solid #aaa;
    margin-top: 3px;
    border-radius: 5px;
}


.tt-suggestion {
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tt-cursor {
    background-color: #EEE !important;
}

.tt-dataset:not(:first-child) {
    border-top: 1px solid red;
}


.tt-suggestion img, #tab-best > div img {
    max-height: 30px;
    max-width: 20px;
    vertical-align: middle;
    padding-right: 5px;
    width: 20px;
    display: inline-block;
}

.tt-suggestion img[src=""] {
    visibility: hidden;
}

.tt-suggestion img ~ span, #tab-best > div img ~ span {
    vertical-align: middle;
}

.drugstore_list__timetable {
    display: inline-block;
    white-space: nowrap;
    font-size: 16px;
    margin-top: 2px;
}

.map_drug_price {
    font-size: 1.1em;
}

.map_drug_name {
    font-size: 0.9em;
}

.map_drug_price_row {
    border-top: 1px #ccc solid;
    padding-top: 3px;
    padding-bottom: 3px;
}

.map_address_name {
    font-size: 1.1em;
}

.map_address_phone {
    color: blue;
}

.map_address_phone .map_address_phone_extension {
    color: #aaa;
}

.map_drugstore_name {
    font-size: 0.9em;
}

.map_drugstore_name > a {
    color: #38c;
}

.ymaps-2-1-69-balloon__content > ymaps > ymaps > a {
    color: #38c;
}

.analog > a {
    color: #38c;
}
