.header__wrap {
  position: relative;
  width: 100%;
  margin-bottom: -3px;
  z-index: 1001;
}

.header {
  display: inline-block;
  width: 100%;
  background-color: white;
  box-shadow: 0 3px 7px #EEEEEE;
  z-index: 1000;
}

.header__search_nojqm {
  background: none;
  background-color: #FFFFFF;
  border: none;
  border-radius: 3px;
  box-shadow: inset 1px 1px 5px silver;
  box-sizing: border-box;
  font-size: 15px;
  height: 30px;
  outline: none;
  padding: 2px 2px 2px 35px;
  width: 100%;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}

.header__btn-menu {
  display: inline-block !important;
  height: 45px;
  width: 15%;
  min-width: 40px;
}

.header__filter {
  display: inline-block;
  height: 45px;
  width: 15%;
  min-width: 40px;
}

.ui-input-search {
  margin: 0;
}

.tt-open {
  width: calc(100% + 2.375em);
}
